<template>
  <div class="loginContent">
    <div class="lg-title">社会化服务平台</div>
    <div class="lg-content">
      <img class="lg-cImg" src="../../assets/image/lgForm.png" alt="" />
      <el-form :model="formData" :rules="formRules">
        <div v-show="contentShow == 0">
          <div>
            <el-form-item>
              <el-input v-model="formData.username" placeholder="用户名"></el-input
            ></el-form-item>
          </div>
          <div>
            <el-form-item>
              <el-input
                type="password"
                v-model="formData.password"
                placeholder="密码"
              ></el-input
            ></el-form-item>
          </div>
          <div>
            <el-form-item class="fitem-code">
              <el-input v-model="formData.code" placeholder="验证码"></el-input>
              <el-tooltip
                class="item"
                effect="light"
                content="点击刷新验证码"
                placement="top-start"
              >
                <img @click="getCodeImg" :src="imgUrl" alt="" />
              </el-tooltip>
            </el-form-item>
          </div>
        </div>
        <!-- <div v-show="contentShow != 0">
          <div>
            <el-form-item>
              <el-input
                v-model="formData.phone"
                placeholder="请输入手机号"
              ></el-input>
            </el-form-item>
          </div>
          <div class="sendCodeContent">
            <el-form-item>
              <el-input v-model="formData.code" placeholder="验证码"></el-input>
              <span
                style="cursor: pointer"
                v-show="!isSendCode"
                @click="sendCode"
                >发送验证码</span
              >
              <span style="cursor: pointer" v-show="isSendCode">{{
                `重新发送(${sendCodeTime})`
              }}</span>
            </el-form-item>
          </div>
          <div v-show="contentShow == 2">
            <el-form-item>
              <el-input
                v-model="formData.newPassword"
                placeholder="新密码"
              ></el-input
            ></el-form-item>
          </div>
        </div> -->
        <div>
          <el-form-item class="fitem-btn">
            <!-- <div :style="contentShow != 2 ? 'width: calc(100% - 90px)' : ''">
              <el-button
                v-if="contentShow != 0"
                type="text"
                @click="contentShow = 0"
                >普通登录</el-button
              >
              <el-button
                v-if="contentShow == 0"
                type="text"
                @click="contentShow = 1"
                >手机验证码登录</el-button
              >
              <el-button
                v-if="contentShow == 0"
                type="text"
                @click="contentShow = 2"
                >重置密码</el-button
              >
            </div> -->
            <div
              v-if="contentShow == 0 || contentShow == 1"
              class="remberMe"
              @click="isRember = !isRember"
            >
              <div class="rm-text1" :id="isRember ? 'is-checked' : ''">记住我</div>
              <div class="rm-text2">{{ isRember ? "&radic;" : "" }}</div>
            </div>
          </el-form-item>
        </div>
        <div>
          <el-form-item class="fitem-button">
            <el-button @click="loginInfo" v-show="contentShow == 0 || contentShow == 1"
              >登录</el-button
            >
            <!-- <el-button v-show="contentShow == 2">重置密码</el-button> -->
          </el-form-item>
        </div>
      </el-form>
    </div>
    <div class="lg-text">隶属单位：湖南精飞智能科技有限公司</div>
  </div>
</template>
<script>
import { sendPhoneVeriCode } from "@/api/serviechall";
import { getCodeImg } from "@/api/login";
import Cookies from "js-cookie";
import { encrypt, decrypt } from "@/utils/jsencrypt";

export default {
  data() {
    return {
      formData: {
        password: "",
        username: "",
        code: "",
        phone: "",
        newPassword: "",
      },
      contentShow: 0,
      isRember: false,
      formRules: {},
      isSendCode: false,
      sendCodeTime: 60,
      setTimeoutEnteval: null,
      imgUrl: "",
      uuid: "",
    };
  },
  methods: {
    sendCode() {
      let veriType = 0;
      let phoneRule = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      let codeReg = /^[0-9]{4,6}$/;
      if (!phoneRule.test(this.formData.phone)) {
        this.$message.error("请输入正确的手机号码");
        return;
      }
      if (codeReg.test(this.formData.code)) {
        veriType = 1;
      }
      let query = { phone: this.formData.phone, veriType };
      this.isSendCode = true;
      this.sendPhoneVeriCode(query);
    },
    sendPhoneVeriCode(query) {
      sendPhoneVeriCode(query).then((res) => {
        const { code, msg } = res;
        if (code === 200) {
          this.$message.success("验证码发送成功");
          this.startTimeout();
        } else {
          this.$message.error(msg || "验证码发送失败");
        }
      });
    },
    startTimeout() {
      this.setTimeoutEnteval = setInterval(() => {
        if (this.sendCodeTime !== 0) {
          this.sendCodeTime--;
        } else {
          clearTimeout(this.setTimeoutEnteval);
          this.isSendCode = false;
          this.sendCodeTime = 60;
        }
      }, 1200);
    },
    getCodeImg() {
      getCodeImg().then((res) => {
        const { code } = res;
        if (code === 200) {
          this.imgUrl = "data:image/gif;base64," + res.img;
          this.uuid = res.uuid;
        }
      });
    },
    loginInfo() {
      if (this.isRember) {
        Cookies.set("serviceUsername", this.formData.username, { expires: 30 });
        Cookies.set("servicePassword", encrypt(this.formData.password), {
          expires: 30,
        });
        Cookies.set("serviceRememberMe", this.rememberMe, { expires: 30 });
      } else {
        // 否则移除
        Cookies.remove("serviceUsername");
        Cookies.remove("servicePassword");
        Cookies.remove("serviceRememberMe");
      }
      const { username, password, code } = this.formData;
      let query = { username, password, code, uuid: this.uuid };
      this.$store.dispatch("loginInfo", query).then((res) => {
        this.$router.push({ path: "/" });
      });
    },
    getUserInfo() {
      this.formData.username = Cookies.get("serviceUsername");
      let password = Cookies.get("servicePassword") || "";
      this.formData.password = decrypt(password);
      this.isRember = Cookies.get("serviceRememberMe");
    },
    getArray(obj) {
      let arr = [];
      for (key in obj) {
        arr.push(key);
      }
    },
    fn1() {
      let list = [];
      return function callback(obj) {
        let arr = [];
        let newObj = {};
        for (let key in obj) {
          arr.push(key);
          if (Object.prototype.toString.call(obj[key]) === "[object Object]") {
            newObj = Object.assign(newObj, obj[key]);
          }
        }
        list.push(arr);
        let keys = Object.keys(newObj);
        if (keys.length != 0) {
          callback(newObj);
        }
        return list;
      };
    },
  },
  created() {
    this.getUserInfo();
    this.getCodeImg();
    // let obj = {
    //   a: { b: { c: null }, d: null, e: { f: null } },
    //   i: { j: { k: null } },
    //   l: null,
    // };
    // console.log(this.fn1()(obj));
    // console.log(this.optNum("!!!"));
    // console.log(this.optNum("@@@"));
  },
  computed: {
    optNum() {
      return (val) => {
        return this.sendCodeTime + val;
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.loginContent {
  width: 100%;
  height: 100vh;
  background: url(../../assets/image/loginBn.png) no-repeat;
  margin: 0px;
  background-size: 100% 100%;
  background-attachment: fixed;
  .lg-title {
    color: #ffffff;
    font-size: 40px;
    margin: 0 auto;
    width: 100%;
    font-weight: 900;
    text-align: center;
    padding-top: 5%;
  }
  .lg-content {
    width: 450px;
    height: 420px;
    position: absolute;
    right: 15%;
    top: 22%;
    border-radius: 5px;
    padding: 90px 50px 0;
    box-sizing: border-box;
    .lg-cImg {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
    }
    .el-form {
      width: 100%;
      // margin: 150px auto 0;
    }
    /deep/ .el-input--small .el-input__inner {
      height: 38px;
      line-height: 38px;
    }
    .fitem-code {
      /deep/ .el-form-item__content {
        display: flex;
      }
      /deep/ .el-input--small,
      .el-input__inner {
        width: calc(100% - 115px);
      }
      img {
        width: 115px;
        height: 38px;
      }
    }
    .fitem-btn {
      /deep/ .el-button--text {
        font-size: 14px;
      }
      /deep/ .el-form-item__content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;
        .remberMe {
          cursor: pointer;
          border: 1px solid #d2d2d2;
          color: #ffffff;
          display: flex;
          .rm-text1 {
            background-color: #d2d2d2;
            height: 30px;
            line-height: 30px;
            text-align: center;
            padding: 0 5px;
          }
          #is-checked {
            background-color: #17a763;
          }
          .rm-text2 {
            background-color: #fff;
            height: 30px;
            width: 30px;
            color: #17a763;
            text-align: center;
            font-family: "黑体";
          }
        }
      }
    }
    .fitem-button {
      /deep/ {
        .el-button {
          background-color: #17a763;
          width: 100%;
          height: 38px;
          font-size: 14px;
          color: #fff;
        }
      }
    }
    .sendCodeContent {
      font-size: 14px;
      color: #757575;
      /deep/ .el-form-item__content {
        display: flex;
        align-items: center;
      }
      /deep/ .el-input--small,
      .el-input__inner {
        width: 180px;
        margin-right: 20px;
        cursor: pointer;
      }
    }
  }
  .lg-text {
    position: absolute;
    left: 0;
    bottom: 6px;
    width: 100%;
    text-align: center;
    color: #fff;
    font-size: 12px;
  }
}
</style>
